import React from 'react';
import ReactDOM from 'react-dom/client';
import YouTubeLive from './components/youtube';

export const isDev = process.env.NODE_ENV === 'development';

const RenderBlock = document.getElementById('react-live-module') as HTMLElement;

if (RenderBlock) {
  const root = ReactDOM.createRoot(RenderBlock);
  // const enableMobile = RenderBlock.getAttribute('data-enable-mobile');
  // const enableDesktop = RenderBlock.getAttribute('data-enable-desktop');

  root.render(<YouTubeLive />);
}
