import React, { useEffect, useRef, useState } from 'react';
import Product from './product';
import ReactPlayer from 'react-player/youtube';
import {
  getDatabase,
  ref,
  limitToLast,
  query,
  onValue,
  push,
  set,
  orderByChild,
  startAt,
  get,
} from 'firebase/database'; // Adjust the path as needed
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { initializeApp } from 'firebase/app';
import 'firebase/database'; // If using Firebase Database
import HighlightProduct from './highlight';
import './youtube.css';

const firebaseConfig = {
  apiKey: 'AIzaSyAiHZ8WsSl4e-M3gciznvoP3sMCMSGLXZ4',
  authDomain: 'enda-combine-stock-shopify-app.firebaseapp.com',
  databaseURL:
    'https://enda-combine-stock-shopify-app-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'enda-combine-stock-shopify-app',
  storageBucket: 'enda-combine-stock-shopify-app.appspot.com',
  messagingSenderId: '550362410585',
  appId: '1:550362410585:web:8f258c40631edf0e518b48',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

interface Live {
  id: string;
  liveUrl: string;
  startTime: number;
  endTime?: number;
  pinnedComment?: string;
  voucher?: {
    code: string;
    description: string;
  };
  products: ProductData[];
}

interface ProductData {
  index: string;
  highlighted: boolean;
  id: number;
  title: string;
  image: string;
  price: string;
  priceSale?: string; // Marked as optional since it may not be present for all products
  url: string;
}

const YouTubeLive: React.FC = () => {
  const [lastMessage, setLastMessage] = useState('');
  const [rateLimited, setRateLimited] = useState(false);
  const [lastMessageTime, setLastMessageTime] = useState(0);
  const [viewers, setViewers] = useState<number>(0);
  const [liveId, setLiveId] = useState<string>('');
  const [live, setLive] = useState<Live | null>(null);
  const [fpHash, setFpHash] = React.useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [muted, setMuted] = useState(true); // Mute the video by default
  const [chatInput, setChatInput] = useState('');
  const [chatMessages, setChatMessages] = useState<
    {
      userId: string;
      text: string;
      timestamp: number;
      name: string;
    }[]
  >([]);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [isDisplayVisible, setIsDisplayVisible] = useState(true);
  const [isProductDivVisible, setIsProductDivVisible] = useState(false);
  const [containerVisible, setContainerVisible] = useState(true);
  const [copied, setCopied] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [customName, setCustomName] = useState<string>('');

  // @ts-ignore
  const customer = window?.__st?.cid;
  // @ts-ignore
  const name = window?.customer_first_name;
  // @ts-ignore
  const shop = (window.Shopify.shop || '').split('.')[0];

  let shopDomain = shop.split('.myshopify.com')[0];

  if (shopDomain === 'sitikhadijahstore') {
    shopDomain = 'sitikhadijah';
  }

  const liveURL = `https://${shopDomain}.com/pages/live-streaming`;

  useEffect(() => {
    setIsFullscreen(window.location.pathname.includes('/pages/live-streaming'));
  }, []);

  useEffect(() => {
    if (isFullscreen) {
      setTimeout(() => {
        setMuted(false);
      }, 5000);
    }
  }, [isFullscreen]);

  const VIEWER_REFRESH_INTERVAL = 60 * 1000; // 30 seconds

  useEffect(() => {
    if (liveId) {
      const database = getDatabase(app);
      const liveSessionRef = ref(database, `${shop}/live/sessions/${liveId}/visitors`);

      const fetchActiveViewers = async () => {
        try {
          const snapshot = await get(liveSessionRef);
          const liveSnapshot = snapshot.val() as any;
          if (liveSnapshot) {
            const activeViewer = Object.values(liveSnapshot).filter(
              // only count viewers who are in fullscreen mode and have been active in the last 60 seconds
              (viewer: any) =>
                viewer.isFullscreen && viewer.timestamp > Date.now() - VIEWER_REFRESH_INTERVAL,
            );
            setViewers(activeViewer.length);
          } else {
            setViewers(0);
          }
        } catch (error) {
          console.error('Error fetching active viewers:', error);
          setViewers(0);
        }
      };
      fetchActiveViewers();
      const id = setInterval(fetchActiveViewers, VIEWER_REFRESH_INTERVAL);
      return () => clearInterval(id);
    }
  }, [liveId]);
  useEffect(() => {
    const database = getDatabase(app);
    const liveRef = ref(database, `${shop}/live/active_session`);
    return onValue(liveRef, (snapshot) => {
      const liveId = snapshot.val();
      setLiveId(liveId);
    });
  }, []);

  useEffect(() => {
    // fetch live object
    if (!liveId) return;
    const database = getDatabase(app);
    const liveRef = ref(database, `${shop}/live/sessions/${liveId}`);
    return onValue(liveRef, (snapshot) => {
      const live: Live = snapshot.val();
      setLive({
        ...live,
        id: liveId,
        products: live.products || [],
      });
    });
  }, [liveId]);

  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      // generate random id if visitorId is not available
      setFpHash(visitorId || Math.random().toString(36).substring(2));
    };
    setFp();
  }, []);

  useEffect(() => {
    if (liveId && fpHash) {
      // check if live is active
      if (!live || live.startTime > Date.now() || (live.endTime && live.endTime < Date.now())) {
        return;
      }
      // store in db keyed by fpHash with value of timestamp called every third of VIEWER_REFRESH_INTERVAL
      const database = getDatabase(app);
      const liveSessionRef = ref(database, `${shop}/live/sessions/${liveId}/visitors/${fpHash}`);
      const id = setInterval(async () => {
        await set(liveSessionRef, {
          timestamp: Date.now(),
          customer: customer || null,
          name: name || null,
          isFullscreen,
          isMuted: muted,
        });
        return () => id && clearTimeout(id);
      }, VIEWER_REFRESH_INTERVAL / 3); // save active viewer every third of VIEWER_REFRESH_INTERVAL,
      // later to be used to count active viewers
    }
  }, [live?.id, liveId, fpHash]);

  useEffect(() => {
    if (chatContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = chatContainerRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 100; // 100 is a threshold

      // Only scroll to the bottom if the user is already at the bottom (or within a threshold)
      if (isAtBottom) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }
  }, [chatMessages]); // Dependency array includes chatMessages to trigger effect on update

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [isFullscreen]); // Dependency array includes chatMessages to trigger effect on update

  // chat always show latest, and if new comment is added, move to latest
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  useEffect(() => {
    const database = getDatabase(app);
    const messagesRef = query(
      ref(database, `${shop}/live/sessions/${liveId}/chats`),
      limitToLast(30),
    );

    return onValue(messagesRef, (snapshot) => {
      const messages = snapshot.val();
      const fetchedMessages = [];
      for (let id in messages) {
        fetchedMessages.push(messages[id]);
      }
      setChatMessages(fetchedMessages);
    });
  }, [liveId]);

  const handleChatInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // limit to 100 characters
    if (e.target.value.length > 100) return;
    setMessage(e.target.value);
  };

  const sendChatMessage = (customMessage?: string) => {
    const msg = customMessage || message;
    let inputName = customer ? name : customName;

    if (!msg.trim()) return false; // Check user is signed in

    if (!inputName) {
      // display prompt to enter name
      inputName = window.prompt('Please enter your name') || 'Guest';
      if (!inputName) return false;
      setCustomName(inputName);
    }

    if (msg.trim() === lastMessage) {
      setMessage('');
      return false;
    } // Check if the message is the same as the last one (to prevent spamming the same message)

    // rate limit to 1 message per 2 seconds
    if (Date.now() - lastMessageTime < 2000) {
      setRateLimited(true);
      setTimeout(() => {
        setRateLimited(false);
      }, 3000);
      return false;
    }

    const messageObject = {
      userId: customer || fpHash,
      name: inputName,
      text: msg.trim(),
      timestamp: Date.now(),
    };
    push(ref(getDatabase(app), `${shop}/live/sessions/${liveId}/chats`), messageObject);
    setLastMessageTime(Date.now());
    setLastMessage(msg.trim());
    setMessage('');
    return true;
  };

  const toggleFullscreen = () => {
    if (isFullscreen) {
      window.location.href = '/';
    } else {
      window.location.href = '/pages/live-streaming';
    }
  };

  const toggleDisplayVisibility = () => {
    setIsDisplayVisible(!isDisplayVisible);
  };

  const toggleProductDivVisibility = () => {
    setIsProductDivVisible(!isProductDivVisible);
  };

  // Styles for the container that will be either fixed or sticky positioned
  const containerStyle = {
    position: 'fixed',
    bottom: isFullscreen ? '0' : '15%',
    left: 0,
    width: isFullscreen ? '100%' : '165px',
    height: isFullscreen ? '100%' : '210px',
    background: 'black',
    transition: 'all 0.5s ease-in-out',
    zIndex: 9999998,
    display: containerVisible ? 'flex' : 'none',
    flexDirection: 'column',
  };

  // Iframe container style, adjusting based on fullscreen/minimized state
  const iframeContainerStyle = {
    position: 'relative',
    transition: 'all 0.5s ease-in-out',
    width: '100%', // Full width in fullscreen, 70% width in minimized
    height: '100%', // 70% height in fullscreen, full height in minimized
  };

  // Style for the blank div used for product information
  const infoDivStyle = {
    transition: 'all 0.5s ease-in-out',
    width: isFullscreen ? '100%' : '40%',
    height: isFullscreen ? '' : '100%',
    // background: 'white',
    // overflowY: isFullscreen ? 'hidden' : 'auto', // Vertical scroll in minimized mode
    // overflowX: isFullscreen ? 'auto' : 'hidden', // Horizontal scroll in fullscreen mode
    overflow: 'auto',
    display: isFullscreen ? 'flex' : 'none',
    zIndex: '9999999',
    position: 'absolute',
    bottom: '70px',
    // flexDirection: isFullscreen ? 'row' : 'column', // Adjust direction based on state
  };

  if (!live) {
    return null;
  }

  if (live.endTime && live.endTime < Date.now()) {
    return <div>Live has ended</div>;
  }

  if (live.startTime > Date.now()) {
    return <div>Live has not started</div>;
  }

  const sortedProducts = live.products.sort((a, b) =>
    a.highlighted === b.highlighted ? 0 : a.highlighted ? -1 : 1,
  );

  const toggleContainerVisibility = () => {
    setContainerVisible(!containerVisible);
  };

  const copyToClipboard = () => {
    const textToCopy = liveURL;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
        window.alert('Link has been copied!');
      })
      .catch((err) => console.error('Failed to copy:', err));
  };

  // @ts-ignore
  return (
    // @ts-ignore
    <div style={containerStyle}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex' }}>
          <div
            style={{
              borderRadius: '5px',
              zIndex: 9998,
              color: 'white',
              background: 'red',
              placeSelf: 'center',
              padding: '3px 10px',
              margin: '3px',
            }}
          >
            LIVE
          </div>
          {isFullscreen && (
            <div
              style={{
                borderRadius: '5px',
                padding: '0.5rem',
                zIndex: 9998,
                color: 'white',
                placeSelf: 'center',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                style={{
                  width: isFullscreen ? '20px' : '15px',
                  marginRight: '5px',
                }}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                />
              </svg>
              {viewers}
            </div>
          )}
        </div>
        <div>
          {isFullscreen && (
            //clear display
            <button
              style={{
                borderRadius: '5px',
                padding: '0.5rem',
                zIndex: 9998,
                textAlign: 'left',
                border: 'none',
                outline: 'none',
                backgroundColor: 'transparent',
              }}
              onClick={() => {
                toggleDisplayVisibility();
              }}
            >
              {isDisplayVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  style={{ width: isFullscreen ? '20px' : '15px', color: 'white' }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  style={{ width: isFullscreen ? '20px' : '15px', color: 'grey' }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              )}
            </button>
          )}
          {isFullscreen && (
            //share button
            <button
              style={{
                borderRadius: '5px',
                padding: '0.5rem',
                zIndex: 9998,
                textAlign: 'left',
                border: 'none',
                outline: 'none',
                backgroundColor: 'transparent',
              }}
              onClick={copyToClipboard}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                style={{ width: '20px', color: 'white' }}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
                />
              </svg>
            </button>
          )}
          <button
            //muted
            style={{
              // add border radius and padding for better visibility and clickability
              borderRadius: '5px',
              padding: '0.5rem',
              zIndex: 9998, // Ensure the button is above both the iframe and the info div
            }}
            onClick={() => {
              setMuted(!muted);
            }}
          >
            {muted ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                style={{ width: isFullscreen ? '20px' : '15px', color: 'grey' }}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                style={{ width: isFullscreen ? '20px' : '15px', color: 'white' }}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
                />
              </svg>
            )}
          </button>
          <button
            //minimize/fullscreen
            style={{
              // add border radius and padding for better visibility and clickability
              borderRadius: '5px',
              padding: '0.5rem',
              zIndex: 9998, // Ensure the button is above both the iframe and the info div
            }}
            onClick={toggleFullscreen}
          >
            {isFullscreen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                style={{ width: isFullscreen ? '20px' : '15px', color: 'white' }}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 4.5-15 15m0 0h11.25m-11.25 0V8.25"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                style={{ width: isFullscreen ? '20px' : '15px', color: 'white' }}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                />
              </svg>
            )}
          </button>
          <button
            style={{
              borderRadius: '5px',
              padding: '0.5rem',
              zIndex: 9998,
            }}
            onClick={toggleContainerVisibility}
          >
            {!isFullscreen && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                style={{ width: '15px', color: 'white' }}
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            )}
          </button>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          transition: 'all 0.5s ease-in-out',
          flexDirection: isFullscreen ? 'column' : 'row', // Column in fullscreen, row in minimized
        }}
      >
        {/*@ts-ignore*/}
        <div style={iframeContainerStyle}>
          <ReactPlayer
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              // zIndex: -1, // Ensure the iframe is above the info div
            }}
            width={isFullscreen ? '100%' : ''}
            height={isFullscreen ? '100%' : ''}
            playing
            // muted={isFullscreen ? false : muted} // Unmute when in fullscreen mode
            muted={muted} // Unmute when in fullscreen mode
            url={live.liveUrl}
          />
        </div>
      </div>

      {live.pinnedComment && isFullscreen && isDisplayVisible && !isProductDivVisible && (
        <div
          style={{
            position: 'absolute',
            top: '180px',
            left: '0',
            width: '100%',
            overflowY: 'auto',
            color: 'white',
            padding: '5px 30px',
            zIndex: 9999998,
            animation: 'fadeSlideIn 0.5s ease',
          }}
          className="center-item"
        >
          <div
            style={{
              background: '#00000026',
              borderRadius: '5px',
              padding: '4px 14px',
              width: 'fit-content',
              color: 'white',
            }}
          >
            <span>
              <strong style={{ color: '#6f1920', marginRight: '3px' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  style={{ width: '15px', color: '#6f1920', marginRight: '3px' }}
                >
                  <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                </svg>
                Host:
              </strong>
              {live.pinnedComment}
            </span>
          </div>
        </div>
      )}

      {/* Chat Messages Overlay */}
      {isFullscreen && isDisplayVisible && !isProductDivVisible && (
        <div
          ref={chatContainerRef}
          style={{
            position: 'absolute',
            bottom: '75px',
            left: '0',
            width: '100%',
            maxHeight: '20%',
            overflowY: 'auto',
            color: 'white',
            padding: '5px',
            zIndex: 9999998,
          }}
        >
          {chatMessages.map((message, index) => (
            <div
              key={index}
              style={{
                marginBottom: '5px',
                background: '#00000026',
                borderRadius: '20px',
                padding: '4px 14px',
                width: 'fit-content',
                color: 'white',
              }}
            >
              <span>
                <strong style={{ color: 'antiquewhite' }}>
                  {message.name || message.userId} :{' '}
                </strong>
                {message.text}
              </span>
            </div>
          ))}
        </div>
      )}

      {/* only highlighted product */}
      {isFullscreen && !isProductDivVisible && isDisplayVisible && (
        <div
          style={{
            position: 'absolute',
            top: '6%',
            placeSelf: 'center',
            width: 'fit-content',
          }}
        >
          {sortedProducts.map(
            (product, index) =>
              product.highlighted && (
                <HighlightProduct
                  key={index}
                  highlighted={product.highlighted}
                  imageUrl={product.image}
                  title={product.title}
                  price={product.price}
                  strikethroughPrice={product.priceSale}
                  productUrl={product.url}
                  index={product.index.toString()} // Convert index to string for display
                />
              ),
          )}
        </div>
      )}

      {/* Chat Input - Visible only in fullscreen */}
      {isFullscreen && (
        <div style={{ position: 'absolute', bottom: '0', left: '0', width: '100%' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#f7f1f0',
              padding: '10px',
              zIndex: 9999999,
            }}
          >
            <button
              onClick={toggleProductDivVisibility}
              style={{
                bottom: '10%',
                right: '10px',
                backgroundColor: '#6f1920',
                borderRadius: '18px',
                padding: '8px',
                cursor: 'pointer',
                zIndex: 99999999, // Ensure it's above other elements
                marginRight: '7px',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                style={{ color: '#f7f1f0', width: '27px' }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            </button>
            <>
              <input
                type="text"
                value={message}
                onChange={handleChatInputChange}
                style={{
                  flexGrow: 1,
                  marginRight: '10px',
                  padding: '8px',
                  border: 'none',
                  borderRadius: '5px',
                  outline: 'none',
                }}
                placeholder="Type your message here..."
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    sendChatMessage();
                  }
                }}
              />
              <button
                onClick={() => sendChatMessage()}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  outline: 'none',
                  cursor: 'pointer',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  style={{ color: '#6f1920', width: '20px' }}
                >
                  <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                </svg>
              </button>
            </>
          </div>
        </div>
      )}

      {/*@ts-ignore*/}
      {isFullscreen && isProductDivVisible && (
        <div>
          {live && live.voucher ? (
            <div
              style={{
                zIndex: '9999999',
                position: 'absolute',
                top: '80px',
                right: '10px',
                margin: '5px 15px',
                background: '#faeeec',
                border: '2px solid #6f1920',
                padding: '5px 18px',
                borderRadius: '15px',
                animation: 'blinkingBorder 2s infinite',
              }}
            >
              <h4 style={{ fontWeight: 'bold', margin: '8px 0px 1px' }}>{live.voucher.code}</h4>
              <p style={{ margin: '0px 0px 2px', fontSize: '12px' }}>{live.voucher.description}</p>
              <button
                onClick={() => {
                  navigator.clipboard
                    .writeText(live?.voucher?.code || '')
                    .then(() => {
                      setCopied(true);
                      setTimeout(() => {
                        setCopied(false);
                      }, 3000);
                    })
                    .catch((err) => console.error('Failed to copy:', err));
                }}
                style={{
                  cursor: 'pointer',
                  background: '#6f1920',
                  padding: '5px 20px',
                  borderRadius: '5px',
                  color: 'white',
                  width: '100%',
                  marginBottom: '8px',
                  fontSize: '12px',
                }}
              >
                {copied ? 'Copied!' : 'Copy Code'}
              </button>
            </div>
          ) : null}
          <div
            style={{
              ...infoDivStyle,
              animation: isProductDivVisible
                ? 'productIn 0.5s forwards'
                : 'productOut 0.5s forwards',
            }}
          >
            {sortedProducts.map((product, index) => (
              <Product
                key={index}
                highlighted={product.highlighted}
                imageUrl={product.image}
                title={product.title}
                price={product.price}
                strikethroughPrice={product.priceSale}
                productUrl={product.url}
                index={product.index} // Convert index to string for display
                sendMessage={sendChatMessage}
              />
            ))}
          </div>
        </div>
      )}
      {rateLimited && (
        <div
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            background: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            padding: '10px',
            zIndex: '9999999',
            borderRadius: '5px',
          }}
        >
          Please wait before sending new message
        </div>
      )}
    </div>
  );
};

export default YouTubeLive;
