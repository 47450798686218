import React, { useState } from 'react';

interface ProductProps {
  highlighted: boolean;
  imageUrl: string;
  title: string;
  price: string;
  strikethroughPrice?: string;
  productUrl: string;
  index: string;
  sendMessage: (message: string) => boolean;
}

const Product: React.FC<ProductProps> = ({
  highlighted,
  imageUrl,
  title,
  price,
  strikethroughPrice,
  productUrl,
  index,
  sendMessage,
}) => {
  const [requestSent, setRequestSent] = useState(false);

  const sendAskHostMessage = () => {
    const message = `Please introduce item ${index} (${title})`;
    const isSent = sendMessage(message);
    if (isSent) {
      setRequestSent(true);
      setTimeout(() => {
        setRequestSent(false);
      }, 5000);
    }
  };

  return (
    <div
      style={{
        border: highlighted ? '3px solid #6f1920' : '',
        borderRadius: '5px',
        background: highlighted ? '#f7f1f0' : 'white',
        padding: '10px',
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '185px', // Ensure minimum width for horizontal scroll
        position: 'relative', // Needed for absolute positioning of the index
        height: 'auto',
        justifyContent: 'space-evenly',
      }}
    >
      {/* Number Badge */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '30px',
          height: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px',
          fontWeight: 'bold',
          transform: 'translate(-50%, -50%)', // Center the badge on the corner
          zIndex: 2, // Ensure it's above other content
          background: highlighted ? '#6f1920' : '#dedede',
          borderRadius: '5px',
          color: highlighted ? 'white' : '#a4a3a3',
          padding: '3px',
          marginLeft: '20px',
          marginTop: '20px',
        }}
      >
        {index} {/* Displaying index starting from 1 */}
      </div>
      <img
        src={imageUrl}
        alt={title}
        style={{ width: '65px', height: 'auto', boxShadow: '0 4px 6px rgb(0 0 0 / 7%)' }}
      />
      <h4
        style={{
          display: 'inline-block',
          padding: '5px 20px',
          fontSize: '12px',
          marginBottom: '0',
          textAlign: 'center',
          maxWidth: '180px',
        }}
      >
        {title}
      </h4>
      <p style={{ padding: '10px 5px', fontSize: '13px', marginBottom: '0' }}>
        <span style={{ textDecoration: 'line-through', color: '#bcbcbc' }}>RM{price}</span>
        {'  '}
        {strikethroughPrice && <span style={{ color: '#6f1920' }}>RM{strikethroughPrice}</span>}
      </p>
      <a
        href={productUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none', width: '100%' }}
      >
        <button
          style={{
            cursor: 'pointer',
            background: '#6f1920',
            padding: '5px 20px',
            borderRadius: '5px',
            color: 'white',
            width: '100%',
          }}
        >
          Buy Now
        </button>
      </a>{' '}
      <button
        onClick={sendAskHostMessage}
        style={{
          cursor: 'pointer',
          padding: '3px 10px',
          borderRadius: '15px',
          width: 'fit-content',
          color: 'black',
          position: 'absolute',
          top: '75px',
          background: '#f4e5e5c2',
          fontSize: '12px',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          style={{ width: '14px' }}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
          />
        </svg>
        Ask Host
      </button>
      {requestSent && (
        <div
          style={{
            position: 'fixed',
            bottom: '100px',
            left: '50%',
            transform: 'translateX(-50%)',
            background: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            zIndex: '9999999',
          }}
        >
          Your request has been sent to the host.
        </div>
      )}
    </div>
  );
};

export default Product;
